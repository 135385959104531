import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AgmCoreModule } from '@agm/core';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared.module';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { CookieAlertComponent } from './components/cookie-alert/cookie-alert.component';





firebase.initializeApp(environment.firebase);

@NgModule({
  declarations: [AppComponent, CookieAlertComponent],
  imports: [
    SharedModule,
    BrowserModule,
   
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: environment.firebase.apiKey,
      libraries: ['places']
    })
  ],
  providers: [AngularFirestore, CookieService],
  bootstrap: [AppComponent ]
})
export class AppModule {}
