import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormControl, Validators } from '@angular/forms';

import { footerLinks } from '../../../utils/elements';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerLinks = footerLinks;
  subscribing = false;
  error = '';

  email = new FormControl('', [
    Validators.email,
    Validators.required
  ]);

  constructor(private http: HttpClient) { }

  ngOnInit() {}

  validateEmail(): boolean {
    return !this.email.invalid;
  }

  onSubmitResponse(error: string): void {
    this.subscribing = false;
    this.email.setValue('');
    this.error = error;
  }

  onFormSubmit(e): void {
    e.preventDefault();
    const url = 'https://wayaj.us18.list-manage.com/subscribe/post-json?u=450df859c21ad6133ada90d4c&amp;id=da9abb3f81&';

    const validEmail = this.validateEmail();

    if (validEmail) {
      this.subscribing = true;

      const params = new HttpParams()
        .set('EMAIL', this.email.value)
        .set('b_450df859c21ad6133ada90d4c_da9abb3f81', '');

      this.http.jsonp<MailChimpResponse>(`${url}${params.toString()}`, 'c')
        .subscribe(
          ({ result }) => {
            if (result && result !== 'error') {
              return this.onSubmitResponse('');
            }

            const error = `${this.email.value} probably already exists!`;
            this.onSubmitResponse(error);
          },
          () => {
            this.onSubmitResponse('Unexpected error occurred. Try again.');
          }
        );

      return;
    }

    this.error = 'Enter a Valid E-mail Address'
  }
}
