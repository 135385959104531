export class Message {
  text: string;
  user: boolean;
  answering: boolean

  constructor(text: string, user: boolean, answering: boolean) {
    this.text = text;
    this.user = user;
    this.answering = answering;
  }
}
