import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  error: any = '';
  flag = this.auth.loginModalFlag;
  resetemail = '';
  showMessage = '';
  pattern = "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$";
  loginForm = this.fb.group({
    email: ['', [Validators.required,  Validators.pattern(this.pattern)]],
    password: ['', [Validators.required]]
  });

  signupForm = this.fb.group({
    name: ['', [Validators.required]],
    email1: ['', [Validators.required]],
    password1: ['', [Validators.required]],
    verifyPwd: ['', [Validators.required]]
  });

  constructor(
    private db: AngularFirestore,
    private afAuth: AngularFireAuth,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private auth: AuthService) { }

  ngOnInit() {
    this.auth.IsLogin$.subscribe(isLogin => {
      if (isLogin) {
        this.activeModal.close('Close click');
      }
    })
  }

  /* Email getter methord */
  get email() {
    return this.loginForm.get('email');
  }
  /* Password getter methord */
  get password() {
    return this.loginForm.get('password');
  }

  get name() {
    return this.signupForm.get('name');
  }
  /* Email getter methord */
  get email1() {
    return this.signupForm.get('email1');
  }
  /* Password getter methord */
  get password1() {
    return this.signupForm.get('password1');
  }
  get verifyPwd() {
    return this.signupForm.get('verifyPwd');
  }

  async login() {
    try {
      const result = await this.auth.login(this.loginForm.get('email').value, this.loginForm.get('password').value);
      if(result.user.emailVerified){
        this.auth.changeIsLogin(result.user)
      }
      if(!result.user.emailVerified){
        this.error = 'Email address is not verify';
      }
    } catch (err) {
      console.log(err)
      this.error = err.message;
    }
  }

  async signup() {
    if (this.signupForm.valid) {
      if (this.signupForm.get('password1').value !== this.signupForm.get('verifyPwd').value) {
        this.error = 'Password does not match';
        return
      }
      try {
        let name = this.signupForm.get('name').value;
        this.error='';
        const data = await this.auth.signup(this.signupForm.get('email1').value, this.signupForm.get('password1').value);
/*         environment.production ?
          await data.user.sendEmailVerification({
            url: 'https://www.wayaj.com',
            handleCodeInApp: false
          }) :
          await data.user.sendEmailVerification();*/
        const newName: any = { displayName: name };
        data.user.updateProfile(newName); 
        await this.db.collection('users').doc(this.afAuth.auth.currentUser.uid).set(
          {
            accountType: 'Traveler',
            name,
          }, { merge: true }
        );
        this.flag = 3;
      } catch (err) {
        this.error = err.message;
      }
    }
  }

  resetPass() {
    this.showMessage = '';
    if (this.validateEmail(this.resetemail)) {
      return this.afAuth.auth.sendPasswordResetEmail(this.resetemail)
        .then(() => {
          this.flag = 5;
          this.showMessage = 'An email has been sent to you. Please check your mail and follow the instructions.';
        })
        .catch(() => {
          this.showMessage = 'There was an unexpected error. Please try again.';
        });
    } else {
      if(this.resetemail==''){
        this.showMessage = 'Please Enter Your Email';
      }else{
        this.showMessage = 'Invalid Email, Verify your email address';
      }
    }
  }

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


}
