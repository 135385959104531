import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    const toRedirect = sessionStorage.getItem('fromRedirect') === 'true';
    if (toRedirect) {
      const redirectUri = sessionStorage.getItem('redirectUri');
      sessionStorage.removeItem('fromRedirect');
      sessionStorage.removeItem('redirectUri');
      this.router.navigate([redirectUri]);
    }

    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
