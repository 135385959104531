export const reasons = [
  {
    title: 'Hotel Sustainability',
    title2: 'Rating (HSR)',
    text:
      'How sustainable is your hotel really? Wayaj evaluates member properties based on 7 eco/socio categories—identifying successes and areas for improvement. Let us help your hotel prepare for sustainable growth.',
    // src: require('../assets/images/home/windmill.svg'),
   // alt: 'Windmill'
  },
  {
    title: 'Alight.travel',
    title2: 'Booking Platform',
    text:
      'Member properties have exclusive access to our B2C sales channel—Alight.travel, where mindful travelers browse sustainable travel ideas, book top sustainable hotels and resorts, and instantly offset their travels at checkout.',
    // src: require('../assets/images/home/sample-tube.svg'),
   // alt: 'Sample Tube'
  },
  {
    title: 'IMPPACT™ Program & Carbon Footprint',
    title2: 'Calculating Engine (CFC Engine™) ',
    text:
      'We empower hotels to help guests make a difference. Our proprietary carbon offset calculator allows guests to offset their stays, and our positive impact programs are changing people and the planet for good.',
    // src: require('../assets/images/home/earth.svg'),
   // alt: 'Earth'
  }
];

export const destinations = [
  { name: 'Brazil', src: require('../assets/images/home/brazil.jpg') },
  { name: 'Italy', src: require('../assets/images/home/italy.jpg') },
  { name: 'Spain', src: require('../assets/images/home/spain.jpg') },
  { name: 'United States', src: require('../assets/images/home/usa.jpg') },
  { name: 'Indonesia', src: require('../assets/images/home/indonesia.jpg') },
  { name: 'Greece', src: require('../assets/images/home/greece.jpg') },
  { name: 'Africa', src: require('../assets/images/home/africa.jpg') },
  { name: 'Morocco', src: require('../assets/images/home/morocco.jpg') },
  { name: 'Costa Rica', src: require('../assets/images/home/costa-rica.jpg') },
  { name: 'India', src: require('../assets/images/home/india.jpg') }
];

export const involvedBlocks = [
  {
    title: 'Become an Ambassador',
    src: require('../assets/images/common/ambassador.svg'),
    alt: 'Globe',
    link: '/ambassador',
    text:
      'Join Wayaj’s Sustainability Ambassador program to promote sustainable change and fight the climate emergency as you travel. If you are a frequent traveler committed to an environmentally and socially responsible lifestyle, we want to hear from you!',
    btnTxt: 'Learn more'
  },
  {
    title: 'Recommend a hotel',
    src: require('../assets/images/common/recommended.svg'),
    alt: 'People',
    link: '/recommend',
    text:
      'Did you stay at a hotel with outstanding sustainable practices but didn\'t find it on Wayaj? We’re always scouting new hotels from around the world to grow our selection and your suggestions are very valuable, so don’t be shy and let us know. Help your fellow travelers make better travel choices, recommend a hotel you visited!',
    btnTxt: 'Recommend'
  },
  {
    title: 'Invite friends',
    src: require('../assets/images/common/invite.svg'),
    alt: 'House',
    link: '/invite',
    text:
      'We believe that travel can be a positive force to promote a more sustainable way of life throughout the world. If you agree that we can fight climate emergency together, one trip at the time, help us spread the word by inviting your friends to use Wayaj.',
    btnTxt: 'Invite'
  }
];

export const tourismIcons = [
  {
    src: require('../assets/images/home/arrow.svg'),
    alt: 'Arrow',
    title: 'Discover'
  },
  {
    src: require('../assets/images/home/cog.svg'),
    alt: 'Cog',
    title: 'Manage'
  },
  {
    src: require('../assets/images/home/person_heart.svg'),
    alt: 'Person with heart',
    title: 'Personalize'
  },
  {
    src: require('../assets/images/home/calculator.svg'),
    alt: 'Calculator',
    title: 'Calculate'
  },
  {
    src: require('../assets/images/home/leaf-offset.svg'),
    alt: 'Pointy leaf',
    title: 'Offset'
  }
];

export const businessIcons = [
  {
    src: require('../assets/images/home/person.svg'),
    alt: 'Become a member',
    title: 'Become a member'
  },
  {
    src: require('../assets/images/home/certificate.svg'),
    alt: 'Get certified',
    title: 'Get certified'
  },
  {
    src: require('../assets/images/home/briefcase.svg'),
    alt: 'Access green business tools',
    title: 'Access green business tools'
  }
];

export const ecoScoreIcons = [
  {
    src: require('../assets/images/home/community_white.svg'),
    alt: 'Community',
    title: 'Community'
  },
  {
    src: require('../assets/images/home/water_white.svg'),
    alt: 'Water',
    title: 'Water'
  },
  {
    src: require('../assets/images/home/energy_white.svg'),
    alt: 'Energy',
    title: 'Energy'
  },
  {
    src: require('../assets/images/home/materials_white.svg'),
    alt: 'Materials',
    title: 'Materials'
  },
  {
    src: require('../assets/images/home/indoors_white.svg'),
    alt: 'Indoors',
    title: 'Indoors'
  },
  {
    src: require('../assets/images/home/waste_white.svg'),
    alt: 'Waste',
    title: 'Waste'
  },
  {
    src: require('../assets/images/home/management_white.svg'),
    alt: 'Management',
    title: 'Management'
  }
];

export const footerLinks = {
  1: [
    { name: 'Member Hotels', href: 'https://www.wayaj.com/member-hotels' },
    { name: 'Programs & Tools', href: 'https://www.wayaj.com/imppact' },
    
    { name: ' Membership Benefits ', href: 'https://www.wayaj.com/sustainable-hospitality-tools' },
    { name: 'Blog', href: 'http://news.wayaj.com/' }
  ],
  2: [
    // { name: 'CFC Engine™', href: 'https://www.wayaj.com/carbon-footprint-calculator' }
     { name: 'Blog', href: 'http://news.wayaj.com/' }
  ],
  3: [
    { name: 'Privacy policy', href: 'http://news.wayaj.com/privacy-policy' },
    { name: 'Terms of use', href: 'http://news.wayaj.com/terms-of-use/' },
    { name: 'ADA Policy', href: 'http://news.wayaj.com/gdpr-2/' }
  ]
};

export const featuredHotels = [
  'The Merchant House',
  'Intercontinental New York Barclay ',
  'Bom Bom Príncipe Island',
  'The Junei Hotel Kyoto Imperial Palace West',
  'Hotel Pulitzer Barcelona',
  'Columbus Hotel Monte-Carlo'
];

export const wayajInNumbers = [
  { value: 300, title: 'Hotels' },
  { value: 310, title: 'Cities' },
  { value: 109, title: 'Countries' },
  { value: 4300000000, title: 'Tonnes of CO<sub>2</sub> emissions generated by travel in 2022' }
];

export const sustainabilityCriteria = {
  row1: [
    {
      src: require('../assets/images/static-page/hotel-sustainability-rating/community.svg'),
      label: 'Community',
      text: 'Sustainable practices drive tourism to areas that support the local community, including its people and environment. Wayaj’s HSR evaluates employment practices, community impact and involvement, and access to public or green transportation.'
    },
    {
      src: require('../assets/images/static-page/hotel-sustainability-rating/water.svg'),
      label: 'Water',
      text: 'Sustainable practices reduce water consumption, reuse water as possible, use natural sources of water, and treat wastewater. Wayaj’s HSR assesses the implementation of sustainable practices aimed at efficient use of water resources.'
    },
    {
      src: require('../assets/images/static-page/hotel-sustainability-rating/energy.svg'),
      label: 'Energy',
      text: 'Sustainable practices aim to reduce energy consumption and employ clean, renewable sources for the remaining needs. Wayaj’s HSR appraises the implementation of sustainable solutions such as energy metering, energy efficient practices, and renewable energy consumption.'
    },
    {
      src: require('../assets/images/static-page/hotel-sustainability-rating/resources.svg'),
      label: 'Materials & resources',
      text: 'Sustainable practices aim to reduce resource consumption, while supporting the purchase or use of more local and sustainable materials. Wayaj’s HSR evaluates the use of locally manufactured or created products, and the purchase of products or materials with a sustainable supply chain.'
    }
  ],
  row2: [
    {
      src: require('../assets/images/static-page/hotel-sustainability-rating/indoors.svg'),
      label: 'Indoors quality',
      text: 'Sustainable practices promote the quality of the indoor environment resulting in guest satisfaction, health, and well-being. The Wayaj HSR assesses a property’s indoor smoking policies, green cleaning practices, and guest well-being measures.'
    },
    {
      src: require('../assets/images/static-page/hotel-sustainability-rating/waste.svg'),
      label: 'Waste',
      text: 'Sustainable practices provide proper waste disposal for all material types and encourage disposal options that divert waste from landfills and incinerators. Wayaj’s HSR ascertains the proper waste disposal for all material types and rewards waste reduction.'
    },
    {
      src: require('../assets/images/static-page/hotel-sustainability-rating/management.svg'),
      label: 'Management',
      text: 'Socially responsible practices provide a safe, healthy, and non-discriminatory workplace for all genders, races, religions, and social class. Wayaj’s HSR assesses, among other things, a property’s anti-discrimination and exploitation policy, general sustainability plan, and employee sustainability education.'
    },
  ]
};

export const marketingBenefits = {
  row1: [
    {
      src: require('../assets/images/static-page/hotel-sustainability-marketing/revitalized.svg'),
      label: 'Purpose-driven',
      text: 'People are expecting brands not just governments to lead the way in creating a better, fairer world – a real opportunity for purpose-driven businesses.'
    },
    {
      src: require('../assets/images/static-page/hotel-sustainability-marketing/planet-earth.svg'),
      label: 'Revitalized',
      text: 'Be one of the green pioneers leading the way to protect the world on which the tourism industry relies. Be part of creating a healthier industry for people and the planet.'
    },
    {
      src: require('../assets/images/static-page/hotel-sustainability-marketing/guest-experience.svg'),
      label: 'Enrich the guest experience',
      text: 'Your involvement in your local community and the suppliers you partner with all create your unique character and enhance the guest experience.'
    },
  ],
  row2: [
    {
      src: require('../assets/images/static-page/hotel-sustainability-marketing/csr.svg'),
      label: 'More than CSR',
      text: 'CSR has been criticized as an add-on.  Sustainability has to be at the center of your marketing strategy and it must always be authentic and well communicated.'
    },
    {
      src: require('../assets/images/static-page/hotel-sustainability-marketing/business-sense.svg'),
      label: 'It makes business sense',
      text: 'Those businesses who are embracing sustainability are seeing increased sales and opportunities as well as cost savings.'
    },
    {
      src: require('../assets/images/static-page/hotel-sustainability-marketing/community-new.svg'),
      label: 'Wayaj community',
      text: 'Hospitality is all about people and being a Wayaj member offers you the chance to be part of a collaborative community creating a more earth friendly hospitality industry together.'
    },
  ]
};

export const cfcBenefits = {
  row1: [
    {
      src: require('../assets/images/static-page/cfc-engine/benefit-accuracy.svg'),
      label: 'Accuracy',
      text: 'Wayaj combines international standards and scientific calculations with actual data from hotels to offer one of the most accurate hospitality carbon footprint calculators.'
    },
    {
      src: require('../assets/images/static-page/cfc-engine/benefit-flexibility.svg'),
      label: 'Flexibility',
      text: 'Using an integrated approach, the CFC Engine™ allows guests to add multiple room types, travel legs, travelers and multiple transportation options and lots of certified carbon offset options to choose from.'
    },
    {
      src: require('../assets/images/static-page/cfc-engine/benefit-transparency.svg'),
      label: 'Transparency',
      text: 'The carbon compensation credits are provided by projects certified by trusted sources. Hotels are welcome to provide their own local projects to support their local communities.'
    },
  ],
  row2: [
    {
      src: require('../assets/images/static-page/cfc-engine/benefit-ease.svg'),
      label: 'Ease',
      text: 'Developed by hoteliers, travel professionals and sustainability experts who are committed to protecting the planet like you, we have made it very easy for your guests to use and feel good about making the right choice for our planet.'
    },
    {
      src: require('../assets/images/static-page/cfc-engine/benefit-visibility.svg'),
      label: 'Visibility',
      text: 'A User-friendly interface which can be seamlessly integrated with any hotel website, Wayaj’s CFC Engine™ brings all the information at the forefront to make sure it\'s visible and comprehensible to all travelers.'
    },
    {
      src: require('../assets/images/static-page/cfc-engine/benefit-impact.svg'),
      label: 'Impact',
      text: 'Hotels can actively show their commitment in the fight against climate change by allowing their guests to offset the carbon footprint of their trip.  The Wayaj CFC Engine™ allows hotels to stand out as sustainability leaders for their contribution to carbon offset projects.'
    },
  ]
};

export const cfcSlider = [
  {
    image: require('../assets/images/static-page/cfc-engine/kallichoron.png'),
    quote: 'This will allow us to collaborate with other hotels in Greece and all around the world to help reduce carbon emissions!',
    name: 'Maria Mavroudi',
    position: 'General Manager',
    location: 'Kallichoron Hotel - Greece',
  },
  {
    image: require('../assets/images/static-page/cfc-engine/muralto.png'),
    quote: 'What a timely service, we have been wanting a redo of our website content so we can educate our guests on our sustainability best practices',
    name: 'Jose Luis',
    position: 'General Manager',
    location: 'Muralto Hotel - Spain',
  }
];

export const marketingPlans = [
  {
    label: 'One off',
    header: 'Strategy',
    text: 'Integrating a sustainability communications strategy within your existing marketing plan. No green-washing allowed!',
    icon: 'strategy.svg'
  },
  {
    label: 'One off',
    header: 'Showcase',
    text: 'Ensuring your website is doing your sustainability story justice. Content recommendations, keyword research and SEO.',
    icon: 'showcase.svg'
  },
  {
    label: 'Monthly',
    header: 'Storytelling',
    text: 'Communicating authentically your sustainability story through email marketing, your blog and social media.',
    icon: 'storytelling.svg'
  },
  {
    label: 'Monthly',
    header: 'Bespoke',
    text: 'Choose the elements you want from our packages plus the option of sustainability marketing coaching/mentoring.',
    icon: 'bespoke.svg'
  }
];

export const sustainabilityCriteria2 = {
  col1: [
    {
      src: require('../assets/images/static-page/hotel-sustainability-marketing2/materials.svg'),
      label: 'Materials',
      text: 'Sustainable practices aim to reduce resource consumption, while supporting the purchase or use of more local and sustainable materials.'
    },
    {
      src: require('../assets/images/static-page/hotel-sustainability-marketing2/community.svg'),
      label: 'Community',
      text: 'Sustainable practices drive tourism to areas that support the local community, including its people and environment.'
    },
    {
      src: require('../assets/images/static-page/hotel-sustainability-marketing2/waste.svg'),
      label: 'Waste',
      text: 'Sustainable practices provide proper waste disposal for all material types and encourage disposal options that divert waste from landfills and incinerators.'
    },
    {
      src: require('../assets/images/static-page/hotel-sustainability-marketing2/indoors.svg'),
      label: 'Indoors',
      text: 'Sustainable practices promote the quality of the indoor environment resulting in guest satisfaction, health, and well-being.'
    }
  ],
  col2: [
    {
      src: require('../assets/images/static-page/hotel-sustainability-marketing2/management.svg'),
      label: 'Management',
      text: 'Socially responsible practices provide a safe, healthy, and non-discriminatory workplace for all genders, races, religions, and social class.'
    },
    {
      src: require('../assets/images/static-page/hotel-sustainability-marketing2/water.svg'),
      label: 'Water',
      text: 'Sustainable practices reduce water consumption, reuse water as possible, use natural sources of water, and treat wastewater.'
    },
    {
      src: require('../assets/images/static-page/hotel-sustainability-marketing2/energy.svg'),
      label: 'Energy',
      text: 'Sustainable practices aim to reduce energy consumption and employ clean, renewable sources for the remaining needs.'
    }
  ]
};

export const certificationCategories = [{
  image: require('../assets/images/static-page/hotel-sustainability-certification/community.svg'),
  title: 'Community',
}, {
  image: require('../assets/images/static-page/hotel-sustainability-certification/water.svg'),
  title: 'Water',
}, {
  image: require('../assets/images/static-page/hotel-sustainability-certification/energy.svg'),
  title: 'Energy',
}, {
  image: require('../assets/images/static-page/hotel-sustainability-certification/materials.svg'),
  title: 'Materials',
}, {
  image: require('../assets/images/static-page/hotel-sustainability-certification/indoors.svg'),
  title: 'Indoors',
}, {
  image: require('../assets/images/static-page/hotel-sustainability-certification/waste.svg'),
  title: 'Waste',
}, {
  image: require('../assets/images/static-page/hotel-sustainability-certification/management.svg'),
  title: 'Management',
}];

export const certificationLevels = [{
  level: 1,
  description1: 'Property-provided documentation',
  description2: 'Verified by Wayaj',
}, {
  level: 2,
  description1: 'Property-provided documentation',
  description2: 'Verified by Wayaj & Travelers',
}, {
  level: 3,
  description1: 'In-person audit',
  description2: 'Audited & Approved by Wayaj',
}];


export const sustainabilityComplianceCriteria = {
  row1: [
    { src: require('../assets/images/static-page/hotel-sustainability-marketing2/water.svg'),
      label: 'Water',
      text: 'Establish a water saving management plan to improve conservation and reduce water consumption.'
    },
    {
      src: require('../assets/images/static-page/hotel-sustainability-marketing2/energy.svg'),
      label: 'Energy',
      text: 'Energy reduction practices generate significant savings and can be adapted to suit each property & budget.'
    },
    {
      src: require('../assets/images/static-page/hotel-sustainability-marketing2/materials.svg'),
      label: 'Materials & Resources',
      text: 'A program to cover sustainable sourcing for improving your eco-rating, reduce waste and cut costs.'
    },
    // {
    //   src: require('../assets/images/static-page/hotel-sustainability-marketing2/indoors.svg'),
    //   label: 'Indoors',
    //   text: 'Sustainable practices promote the quality of the indoor environment resulting in guest satisfaction, health, and well-being.'
    // }
  ],
  row2: [
    {
      src: require('../assets/images/static-page/hotel-sustainability-marketing2/management.svg'),
      label: 'Indoors',
      text: 'Improving indoor air quality is of vital importance due to Covid-19 as well reducing possible irritants for guests with allergies.'
    },
    {
      src: require('../assets/images/static-page/hotel-sustainability-marketing2/waste.svg'),
      label: 'Waste',
      text: 'A comprehensive waste audit including trash volumes, types and origins, will reduce volumes and costs.'
    },
    {
      src: require('../assets/images/static-page/hotel-sustainability-marketing2/community.svg'),
      label: 'Community',
      text: 'A community support program to improve your engagement with the local community and its ecosystem.'
    }
  ]
};