import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Subject, of, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

import { Message } from './utils/message.model';
import { Params } from './utils/params.interface';
import { Intent } from './utils/intent.enum';

@Injectable({
  providedIn: 'root'
})
export class ChatBotService {
  chatWindow$ = new Subject<boolean>();
  isChatOpen = false;
  chatEndpoint = 'https://us-central1-wayaj-191117.cloudfunctions.net/superLlama';
  sessionId = '';

  messages: Message[] = [
    new Message('Hi! how can I help you?', false, false)
  ];

  constructor(private http: HttpClient) {}

  toggleChatWindow(): void {
    this.isChatOpen = !this.isChatOpen;
    this.chatWindow$.next(this.isChatOpen);
  }

  onGetMessages(): Observable<Message[]> {
    return of(this.messages);
  }

  newUserMessage(message: string, redirect: boolean): void {
    if (message) {
      const newMsg = new Message(message, true, false);
      this.messages.push(newMsg);
      this.runChatBot(message, redirect);
    }
  }

  private runChatBot(text: string, redirect: boolean): void {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const chatData = { text, sessionId: '', agent: 'browser' };

    const botAnswer = new Message('', false, true);
    this.messages.push(botAnswer);

    this.http.post(this.chatEndpoint, chatData, { headers })
      .subscribe((res: HttpResponse<JSON>) => {
        if (!this.sessionId) {
          this.sessionId = res['sessionId'];
        }

        const lastBotMessage = this.messages[this.messages.length - 1];
        lastBotMessage.answering = false;
        lastBotMessage.text = res['response'];

        const isQuestion = lastBotMessage.text.charAt(lastBotMessage.text.length - 1) === '?';
        
        if (redirect || !isQuestion) {
          this.chatBotRedirect(res['intent'], res['params']);
        }
      });
  }

  private chatBotRedirect(intent: string, params: Params): void {
    const { location, destinationId } = params;
    const domain = 'https://wayaj.com';

    switch (intent) {
      case Intent.Book:
      case Intent.Hotels:
        this.onRedirect(`${domain}/discover?s=${location}`)
          .subscribe(url => window.location.href = url);
        break;

      case Intent.Details:
        // window.location.href = `https://wayaj.com/discover?s=${location}`;
        break;
      
      case Intent.Offset:
        this.onRedirect(`${domain}/offsetting`)
          .subscribe(url => window.location.href = url);
        break;

      case Intent.Lucky:
        this.onRedirect(`${domain}/destination/${destinationId}`)
          .subscribe(url => window.location.href = url);
        break;

      case Intent.Calc:
        this.onRedirect(`${domain}/calculator`)
          .subscribe(url => window.location.href = url);
        break;

      case Intent.Lama:
        window.open('https://www.youtube.com/watch?v=GP5D2apU2SE', '_blank');
        break;
      
      default:
        window.location.href = 'https://wayaj.com/discover'
    }
  }

  private onRedirect(url: string): Observable<string> {
    const url$ = of(url);
    return url$.pipe(delay(2000));
  }
}
