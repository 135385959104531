import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventHandlerService {

  private projectEvent = new Subject<any>();

  constructor() { }

  sendEventSignal = (_data: any = null) => {
    this.projectEvent.next(_data);
  }

  getEventSignal = (): Observable<any> => {
    return this.projectEvent.asObservable();
  }
}
