import { Component, OnInit } from '@angular/core';

import { ChatBotService } from './chatbot.service';
import { Message } from './utils/message.model';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {
  isChatOpen = false;
  messages: Message[] = [];
  currentMsg = '';

  constructor(private chatBot: ChatBotService) {}

  ngOnInit() {
    this.chatWindowState();
    this.getMessages();
  }

  private chatWindowState(): void {
    this.chatBot.chatWindow$.subscribe(value => {
      this.isChatOpen = value;
    });
  }

  private getMessages(): void {
    this.chatBot.onGetMessages().subscribe(messages => {
      this.messages = messages;
    });
  }

  toggleChatBot(): void {
    this.chatBot.toggleChatWindow();
  }

  addNewMessage(): void {
    this.chatBot.newUserMessage(this.currentMsg, false);
    this.currentMsg = '';
  }

  addDefinedMessage(message: string, redirect: boolean): void {
    this.chatBot.newUserMessage(message, redirect);
  }
}
