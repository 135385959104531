import { Component, OnInit, Input, ViewChild, HostListener } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../login/login.component';
import { AuthService } from '../../services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { EventHandlerService } from 'src/app/services/event-handler.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @ViewChild('search', {static: false}) search: any;
  @Input('showSearch') showSearch: boolean;
  isLogin: any;
  displayName = '';
  destination = '';
  userData:any;

  isStickyMenuAtive: boolean = false;

  loaderEventSubs: Subscription;

  constructor(
    private auth: AuthService,
    private modalService: NgbModal,
    private eventHandler: EventHandlerService,
  ) {
    this.loaderEventSubs = this.eventHandler
            .getEventSignal()
            .subscribe((_res) => {
                if (_res.doSignUp) {
                  this.signup();
                }
            });
  }

  async ngOnInit() {
    this.auth.IsLogin$.subscribe(isLogin => {
      this.isLogin = isLogin;
      if (isLogin&&isLogin.displayName) {
        this.displayName=isLogin.displayName;
        this.auth.getUserDetails();
      }
      else if((isLogin&&isLogin.displayName==null)||(isLogin&&isLogin.displayName=='')||(isLogin&&isLogin.displayName==undefined)){
        this.displayName=isLogin.email.substring(0, 3);
        this.auth.getUserDetails();
      }
    })
    this.auth.userData$.subscribe(data => {
      console.log(data)
      if(data)
        this.userData=data;
    })
  }


  public isMenuCollapsed = true;

  //CLick to show hide div
  isShown: boolean = false; // hidden by default
  toggleShow() {
    this.isShown = !this.isShown;
    if(this.isShown){
      setTimeout(() => {
        this.search.nativeElement.focus();
      }, 500);
      this.isMenuCollapsed = true;
    }
  }


  open() {
    document.getElementById("navbarSupportedContent").classList.remove('show');
    this.isMenuCollapsed = true;

    this.auth.loginModalFlag = 1;
    const modalRef = this.modalService.open(LoginComponent,
      { centered: true, windowClass: 'login__modal', backdropClass: 'login__backdrop' });
      modalRef.componentInstance.name = 'login';
    }

    signup() {
      document.getElementById("navbarSupportedContent").classList.remove('show');
      this.isMenuCollapsed = true;

    this.auth.loginModalFlag = 2;
    const modalRef = this.modalService.open(LoginComponent,
      { centered: true, windowClass: 'login__modal', backdropClass: 'login__backdrop' });
    // modalRef.componentInstance.name = 'login';
  }

  async logout() {
    await this.auth.logout();
  }

  searchDestination(): void {
    if (!this.destination) {
      return;
    }

    window.location.href = `https://wayaj.com/discover?s=${this.destination}`;
    this.destination = '';
  }

  goTo(){
    window.location.href = environment.firebase.dashboardLink;
  }

  // STICKY HEADER - start
  @HostListener('window:scroll')
  checkScroll() {
    this.isStickyMenuAtive = window.pageYOffset >= 20;
  }
  // STICKY HEADER - end

}
