import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookie-alert',
  templateUrl: './cookie-alert.component.html',
  styleUrls: ['./cookie-alert.component.scss']
})
export class CookieAlertComponent implements OnInit {
  cookiesAccepted = false;

  constructor(private cookieService: CookieService) {}

  ngOnInit() {
    this.cookiesAccepted = this.cookieService.check('cookiesAccepted');
  }

  accept() {
    this.cookieService.set('cookiesAccepted', 'true', undefined, undefined, undefined, undefined, 'Lax');
    this.cookiesAccepted = true;
  }

  reject() {
    window.location.href = 'https://www.wayaj.com';
  }
}
