import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { User } from 'firebase';
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase/app';
import 'firebase/database';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loginModalFlag=1;
  private IsLogin = new BehaviorSubject<any>(null);
  IsLogin$ = this.IsLogin.asObservable();
  changeIsLogin(data) {
    this.IsLogin.next(data);
  }
  private userData = new BehaviorSubject<any>(null);
  userData$ = this.userData.asObservable();
  changeUserData(data) {
    if(data)
    this.userData.next(data);
  }

  user: User;
  constructor(public afAuth: AngularFireAuth, public router: Router) {
    this.afAuth.authState.subscribe(user => {
      /* if (user.emailVerified) { */
        console.log(user);
        this.user = user;
        this.changeIsLogin(user);
        localStorage.setItem('user', JSON.stringify(this.user));
      /* } else {
        this.changeIsLogin(null);
        localStorage.setItem('user', null);
      } */
    })
  }

  async login(email: string, password: string) {
    var result = await this.afAuth.auth.signInWithEmailAndPassword(email, password);
    return result;
  }

  async signup(email: string, password: string) {
    var result = await this.afAuth.auth.createUserWithEmailAndPassword(email, password);
    return result;
  }

  async logout() {
    await this.afAuth.auth.signOut();
    this.changeIsLogin(null);
    localStorage.removeItem('user');
    //this.router.navigate(['admin/login']);
  }

  async getUserDetails(){
    let db = firebase.firestore();
    const userRef = db.collection('users');
    const userData = await userRef.where('uid', '==', firebase.auth().currentUser.uid).get();
    userData.forEach(data => {
      this.changeUserData(data.data());
    });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null;
  }
}
