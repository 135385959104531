import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Component({
  selector: 'app-ngbd-modal-content',
  templateUrl: './newsletter-popup.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./newsletter-popup.component.scss']
})

export class NgbdModalContentComponent {
  newsLetterPostUrl = "https://wayajcommunication.activehosted.com/proc.php";
  subscribing = false;
  error = '';
  email = new FormControl('', [
    Validators.email,
    Validators.required
  ]);
  @Input() offset: number;

  constructor(public activeModal: NgbActiveModal, private http: HttpClient) {}

  validateEmail(): boolean {
    return !this.email.invalid;
  }

  onSubmitResponse(error: string): void {
    this.subscribing = false;
    this.email.setValue('');
    this.error = error;
  }

  submitNewsLetterForm(form: any, e: any): void {
    //console.log(form.value);
    if(this.validateEmail1(form.controls['email'].value))
		  e.target.submit();
	}
  validateEmail1(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
   }

  onFormSubmit(e): void {
    e.preventDefault();
    const url = 'https://wayaj.us18.list-manage.com/subscribe/post-json?u=450df859c21ad6133ada90d4c&amp;id=da9abb3f81&';

    const validEmail = this.validateEmail();

    if (validEmail) {
      this.subscribing = true;

      const params = new HttpParams()
        .set('EMAIL', this.email.value)
        .set('b_450df859c21ad6133ada90d4c_da9abb3f81', '');

      this.http.jsonp<MailChimpResponse>(`${url}${params.toString()}`, 'c')
        .subscribe(
          ({ result }) => {
            if (result && result !== 'error') {
              return this.onSubmitResponse('');
            }

            const error = `${this.email.value} probably already exists!`;
            this.onSubmitResponse(error);
          },
          () => {
            this.onSubmitResponse('Unexpected error occurred. Try again.');
          }
        );

      return;
    }
    this.error = 'Please Enter a Valid E-Mail Address';
  }
}

@Component({
  selector: 'app-newsletter-popup',
  template: ``,
})

export class NewsletterPopupComponent implements OnChanges {
  @Input() offset: Observable<number>;
  private newsletterWasOpened = true;
  // private newsletterWasOpened = flase;

  constructor(
    private modalService: NgbModal
  ) {}

  ngOnChanges(changes) {
    // @ts-ignore
    if (this.offset > 3000 && !this.modalService.hasOpenModals() && !this.newsletterWasOpened) {
      this.open();
      this.newsletterWasOpened = false;
      // this.newsletterWasOpened = true;
    }
  }

  open() {
    const modalRef = this.modalService.open(NgbdModalContentComponent,
      {centered: true, size: 'xl', windowClass: 'newsletter__modal', backdropClass: 'newsletter__backdrop'});
    modalRef.componentInstance.name = 'Newsletter';
  }
}
